import {graphql, useStaticQuery} from "gatsby";
import React, {useState} from "react";
import Header from "src/components/common/header";
import CommonHelmet from "src/components/common/helmet";
import Footer from "src/components/common/footer";
import FullScreenImageWithText from "src/components/common/fullscreen_image_with_text";
import ValueBlock from "src/components/common/value_block";

export default function TechnologyPage() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          technologyPage {
            hero {
              alt
              image
              title
            }
            blocks {
              textFirst
              items {
                alt
                headerImage {
                  alt
                  image
                }
                text
                imageName
                title
              }
            }
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/technology"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 60)
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.technologyPage;
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <Header heroStyle="light" />
        <CommonHelmet />
        {/* Hero */}
        <FullScreenImageWithText
          image_alt={metadata.hero.alt}
          files={data.allFile.edges}
          image_name={metadata.hero.image}
          title={metadata.hero.title}
          style="light"
        />
        {metadata.blocks.map((el) => (
          <ValueBlock
            items={el.items}
            allImages={data.allFile.edges}
            textFirst={el.textFirst}
          />
        ))}
        <Footer />
      </div>
    </main>
  );
}
